body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link{
  color: blue;
  text-decoration: underline;
  font-weight: bolder;
}
.w-100 {
  width: 100% !important;
}
.w-200 {
  width: 200px !important;
}
.ib-block {
  display: inline-block;
}
.m-t-25 {
  margin-top: 25px;
}
.p-t-20 {
  padding-top: 20px;
}
.publisher-link {
    margin-top: 25px;
    display: inline-flex;
}
.auto-debit-txt-box {
  padding-left: 10px !important;
  margin-top: 10px;
}
.beta-version-txt {
  /* margin-top: 5px; */
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-align: center;
}
.dashboard-filter-box {
  padding-right: 5px !important;
  padding-left: 5px !important;
  min-height:auto; 
  /* align-self: 'flex-start' */
}
.invoice-info {
  position: relative;
}
.invoice-info .infoicon {
  position: absolute;
  top: -3px;
}
.override-m-p {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.link-pointer-icon {
  cursor: pointer;
}
.inv-no-dropdown {
  width: 100px !important;
  margin-left: 10px;
}
.p-t-7 {
  padding-top: 7px !important;
}
.blurText {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}