.textClr {
  color: red !important;
}
.error_asterisk {
  color: red;
  // font-size: 16px;
  font-weight: bold;
}
.dropdown__img {
  border-radius: 20px;
  height: 40px;
  width: 40px;
}
/* Style The Dropdown Button */
.dropbtn {
  // background-color: #4CAF50;
  color: white;
  padding: 3px;
  // font-size: 16px;
  border: none;
  cursor: pointer;
  width: 60px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  min-width: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  position: relative;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  // background-color: #3e8e41;
}
.react-bootstrap-table-pagination-total {
  // position: absolute;
  // top: 8px;
}
.btn-group {
  position: absolute;
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-direction: row-reverse;
  vertical-align: middle;
  right: 0;
}
.react-bootstrap-table-page-btns-ul {
  margin-top: 3px;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
.icondisplay3 {
  color: white;
  font-size: 24px !important;
  position: absolute;
  top: 14%;
  left: 47%;
  -webkit-transform: translate(30%, -140%);
  -ms-transform: translate(30%, -140%);
  transform: translate(30%, -140%);
  text-align: center;
  display: none !important;
}
.profile_container:hover .profile__photo {
  filter: blur(5px);
  -webkit-filter: blur(5px);
  // .icondisplay3{
  //   color: #007bff;
  //   z-index: 2;
  //   display: block !important;
  //   // background-color: rgba(0, 0, 0, 0.5);
  //   // width: 50px;
  // }
}
.profile_container:hover .icondisplay3 {
  color: #007bff;
  z-index: 2;
  display: block !important;
}
.template__card {
  border: 1px solid black;
  border-top: none;
}
.template__inner_card {
  border: 3px solid black;
  height: 296px;
}
.viewInfoBadge {
  color: white;
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
}
.table_select_checkbox {
  width: 41px;
  height: 21px;
}
.approvedBadge {
  color: white;
  background: green;
  padding: 6px;
  border-radius: 35px;
}
.rejectedBadge {
  color: white;
  background: red;
  padding: 6px;
  border-radius: 35px;
}
.pendingBadge {
  color: white;
  background: orange;
  padding: 6px;
  border-radius: 35px;
}
.deletedBadge {
  color: white;
  background: red;
  padding: 6px;
  border-radius: 35px;
}
.updatedBadge {
  color: white;
  background: yellow;
  padding: 6px;
  border-radius: 35px;
}
.newbrandBtn {
  padding: 5px 20px;
  font-size: medium;
  // margin-top: 13px;
}

.center_align {
  justify-content: center;
  display: flex;
  padding: 5px;
}

.Img__card {
  border: 1px solid #efefef;
  height: 180px;
  width: 100%;
  margin-bottom: 0px;
  padding: 0px;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.icondisplay1 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-125%, -50%);
  -ms-transform: translate(-125%, -50%);
  transform: translate(-125%, -50%);
  text-align: center;
}

.icondisplay2 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.list_img {
  width: 100%;
  height: 100%;
}

.center_align:hover .overlay {
  opacity: 1;
}

.center_align:hover .list_img {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.center_align:hover .icondisplay1 {
  z-index: 2;
}

.center_align:hover .icondisplay2 {
  z-index: 2;
}
.p-10 {
  padding: 10px;
}
.p-0 {
  padding: 0px;
}
.h-20 {
  height: 20px !important;
}
.h-35 {
  height: 35px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-15 {
  padding-left: 15px !important;
}
.m-l-0 {
  margin-left : 0px !important;
}
.m-r-10 {
  margin-right : 10px !important;
}
.m-l-5 {
  margin-left : 5px;
}
.m-l--5 {
  margin-left : -5px !important;
}
.m-l-10 {
  margin-left : 10px;
}
.m-l-20 {
  margin-left: 20px;
}
.p-l-r-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.m-t-5 {
  margin-top : 5px;
}
.m-t-5-i {
  margin-top : 5px !important;
}
.m-t-10 {
  margin-top : 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 15px;
}
.m-t-20 {
  margin-top : 20px !important;
}
.m-t-25 {
  margin-top : 25px !important;
}
.m-t-40 {
  margin-top : 40px !important;
}
.m-t-15 {
  margin-top : 15px;
}
.m-t-25 {
  margin-top : 25px;
}
.m-t-60 {
  margin-top : 60px !important;
}
.m-r-0 {
  margin-right: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.p-l-r-10 {
  padding-top: 10px !important;
  padding-left: 10px !important;
}
.m-r-5 {
  margin-right: 5px;
}
.m-b-25 {
  margin-bottom : 25px;
}
.m-b-4 {
  margin-bottom: 4px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.distributor-checkbox {
  vertical-align: middle;
    margin-top: -2px;
}
.b-checkbox-contain {
  position: relative;
}
.b-checkbox {
  position: absolute;
  top: 30px;
  left: 25px;
}
.brand-d-s .distributors-row td {
  padding-top: 0;
} 
.brand-d-s .form-control { 
  height : 0px !important;
  margin-top: 5px;
}
.bulk-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.bulk-upload-btn-wrapper .btn {
  width: 100px;
  cursor: pointer;
  // border: 2px solid gray;
  // color: gray;
  // background-color: white;
  // padding: 8px 20px;
  // border-radius: 8px;
  // font-size: 20px;
  // font-weight: bold;
}

.bulk-upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100px;
  height: 30px;
}
.bulk-brand-upload-modal .file-name {
  display: flex;
  font-size: 13px;
  padding-left: 10px;
}
.bulk-brand-upload-modal .file-label {
  padding-top: 5px;
}
.bulk-brand-upload-modal .file-delete {
  padding-top: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.bulk-brand-upload-modal .invalid-file {
  font-size: 14px;
  color: red;
}
.bulk-brand-upload-modal .invalid-title {
  font-size: 14px;
  font-weight: bold;
}
.bulk-brand-upload-modal .group-brand-rbt {
  padding-top: 15px;
  padding-left: 20px;
}
.next-upload-date .MuiTextField-root {
  width: 100% !important;
  position: relative;
}
.next-upload-date .MuiOutlinedInput-input {
  opacity: 0;
}
.next-upload-date .date-value {
    position: absolute;
    margin-top: 8px;
    margin-left: 55px;
}

// .dashboard-invoice-data {

// }

.invoice-data-toogle {
  display: inline-flex;
  justify-content: right;
}

.invoice-data-toogle .title {
    font-size: 14px;
    // font-weight: bold;
    padding-top: 3px;
}

.invoice-data-toogle .toggle-button-cover {
  display: table-cell;
  position: relative; 
  box-sizing: border-box;
  margin-left: 5px;
  width: 75px;
  margin-right: 5px;
}

// .invoice-data-toogle .button-cover {
//   margin: 20px;
//   background-color: #fff;
//   box-shadow: 0 10px 20px -8px #c5d6d6;
//   border-radius: 4px;
// }

// .invoice-data-toogle .button-cover:before { 
//   position: absolute;
//   right: 0;
//   bottom: 0;
//   color: #d7e3e3;
//   font-size: 12px;
//   line-height: 1;
//   padding: 5px;
// }

.invoice-data-toogle .button-cover,
.invoice-data-toogle .knobs,
.invoice-data-toogle .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.invoice-data-toogle .button {
  position: relative;
  top: 50%;
  width: 55px;
  height: 25px;
  margin: -14px auto 0 auto;
  overflow: hidden;
}

.invoice-data-toogle .button.r,
.invoice-data-toogle .button.r .layer {
  border-radius: 100px;
}

.invoice-data-toogle .button.b2 {
  border-radius: 2px;
}

.invoice-data-toogle .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.invoice-data-toogle .knobs {
  z-index: 2;
}

.invoice-data-toogle .layer {
  width: 100%;
  background-color: #ccd2d5;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
.invoice-data-toogle #button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 18px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  // background-color: #958a8a;
  background-color: #8b8b8b;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.invoice-data-toogle #button-1 .checkbox:checked + .knobs:before {
  content: "";
  left: 30px;
  background-color: #3d81f5;
}

.invoice-data-toogle #button-1 .checkbox:checked ~ .layer {
  background-color: #ccd2d5;
}

.invoice-data-toogle #button-1 .knobs,
.invoice-data-toogle #button-1 .knobs:before,
.invoice-data-toogle #button-1 .layer {
  transition: 0.3s ease all;
} 

.action-center .save-upload {
  display: flex;
  justify-content: flex-end;
}
.c-pointer {
  cursor: pointer;
}
.upload-date-box {
  min-height: 89px;
}
.upload-date-box .title {
  font-weight: bold !important;
  font-size: 11px !important;
}
.upload-date-box .sub-title {
  font-weight: unset !important;
  font-size: 11px !important;
}
.MuiRadio-colorSecondary.Mui-checked {
  color: #0075ff !important;
}

.edit-action-center-modal .publisher-box {
  padding-left: 30px;
  margin-top: 10px;
}
.edit-action-center-modal .mail-contain {
  border: 1px solid #dee2e6;
  margin-left: 15px;
  padding: 15px;
  border-radius: 5px;
}
.separate-box {
    border-top: 1px solid #dee2e6;
    margin-top: 15px !important;
    margin-left: 0px !important;
}
.action-center-sub-t {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
  color: #212529 !important;
}
.view-publisher-status {
  color: blue;
  border-bottom: 1px solid;
  font-size: 12px;
  cursor: pointer;
}
.action-center-search {
  position: relative !important;
}
.action-center-search-box {
  position: absolute;
    right: 0;
}

.add-brandBranchBySales-modal .brandbox {
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding-bottom: 25px;
  margin-top: 10px;
}
.add-brandBranchBySales-modal .branchbox {
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding-bottom: 25px; 
  margin-top: 10px;
  margin-left: 10px;
}
.add-brandBranchBySales-modal .brandtitle {
  font-size: 14px;
  font-weight: bold;
  position: relative;
}
.add-brandBranchBySales-modal .addBrandIcon {
    position: absolute;
    right: 0;
}
.p-l-0 {
  padding-left: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.m-l--5 {
  padding-left: -5px !important;
}
.min-h {
  min-height: 50px;
}
.campaign-settings .date-rang-box {
  border: 1px solid #dee2e6; 
    margin: 0;
    padding-bottom: 10px;
    border-radius: 3px;
}
.text-align-left {
  text-align: left;
}
.brand-activity-modal .title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}
.brand-activity-modal .discount-table .border-line {
  border: 1px solid #dee2e6;
  padding: 5px 0px 5px 5px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-1-1 {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  min-width: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown-1-1:hover .dropdown-content {
  display: block;
  z-index: 10;
  // position: relative;
}
.dropdown-1-1 .dropdown-heading {
  height: 28px !important;
}

.min-col-width-200 {
  min-width: 200px !important;
}
.b-c-subtitle {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px !important;
    padding-top: 5px;
    padding-bottom: 5px;
}
.b-c-sub-t-02 {
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px !important;
  padding-top: 5px;
}
.branch-contain-list {
  border: 1px solid #dee2e6;
    margin-top: 20px;
    margin-left: 0px !important;
    border-radius: 5px;
}
.branch-contain-row {
  border-bottom: 1px solid #dee2e6;
  margin-left: -10px !important;
}
.MuiInputLabel-asterisk {
  color: red;
}

.header-title {
  border: 1px solid #dee2e6;
  margin-top: 15px;
  padding: 5px 0px 5px 10px;
  font-weight: bold;
}
.b-box-white {
  border: 1px solid #dee2e6;
  padding: 5px 0px 5px 10px;
  padding-top: 15px;
}